body {
   margin: 0;
}

.r-main {
   background: #3CB0CC;
   /* background: -webkit-linear-gradient(top, #3CB0CC, #CA3756);
   background: -moz-linear-gradient(top, #3CB0CC, #CA3756);
   background: linear-gradient(to bottom, #3CB0CC, #CA3756); */
   background: rgb(226,66,101);
background: linear-gradient(0deg, rgba(226,66,101,1) 34%, rgba(65,172,200,1) 100%);
   width: 100%;
   min-height: 100vh;
   color: #fff;
   position: relative;
   overflow: hidden;
}

.r-container {
   display: flex;
   padding: 2rem 5rem 0rem 5rem;
}

.r-left {
   /* height: 70vh; */
   flex: 1;
   display: flex;
   flex-direction: column;
   text-align: center;
}

.r-modules {
   position: relative;
   display: flex;
   flex-direction: column;
}

.r-module1 {
   flex: 1;
   display: flex;
}

.module1-1 {
   flex: 1;
}

.r-text {
   font-size: 20px;
   font-weight: 400;
   padding: 15px 0px 15px 0px;
}

.r-desc {
   margin: auto;
   width: 70%;
   height: 100px;
   background-color: rgba(237, 30, 121, 0.5);
   border-radius: 20px;
   /* opacity: 0.6; */
   padding: 30px 15px 25px 15px;
   font-size: 14px;
   display: flex;
   align-items: center;
}

.r-circle1 {
   width: 50px;
   height: 50px;
   border-radius: 40px;
   background-color: #D4145A;
   position: absolute;
   z-index: 1;
   display: flex;
   margin-top: -20px;
   justify-content: center;
   align-items: center;
}

.r-circle2 {
   width: 50px;
   height: 50px;
   border-radius: 40px;
   background-color: #D4145A;
   position: absolute;
   z-index: 1;
   margin-top: -20px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.r-circle-outer {
   margin: auto;
   width: 70%;
   display: flex;
   justify-content: center;
}

.r-date {
   padding: 20px;
   margin-top: 1rem;
}

.r-middle {
   flex: 1;
   display: flex;
   flex-direction: column;
   padding-top: 4rem;
}

.r-logo {
   /* flex: 1; */
   display: flex;
   padding: 1rem 0rem 2rem 0rem;
   justify-content: center;
   align-items: center;
}

.r-right {
   flex: 1;
   padding: 3rem 0rem 0rem 0rem;
}

form {
   display: flex;
   flex-direction: column;
}

.label {
   font-size: 15px;
   padding: 2px;
}

.input {
   width: 95%;
   height: 1.2rem;
   border: none;
   border-radius: 5px;
   padding: 10px;
   font-size: 15px;
}

.input:focus,
.dropdown:focus {
   outline: none;
}

.dropdown {
   width: 100%;
   height: 2rem;
   margin-bottom: 10px;
   color: grey;
   font-size: 15px;
}

.r-register-button {
   margin-top: 30px;
   width: 30%;
   height: 35px;
   font-size: 15px;
   padding: 5px;
   background-color: #0075BF;
   border: 1px solid #fff;
   color: #fff;
   border-radius: 5px;
   cursor: pointer;
}

.r-register-button:hover {
   opacity: 0.7;
}

.medisageLogo {
   margin-left: 2rem;
   padding: 13px;
}

.s-logo {
   width: 38%;
}

.s-idea-logo {
   width: 60%;
}

.error {
   color: rgb(43, 27, 27);
   font-size: 12px;
}

.suggestionsDiv {
   width: 99%;
   max-height: 150px;
   overflow-y: scroll;
   background-color: #fff;
   color: grey;
   border-radius: 5px;
   margin-top: 5px;
}

.suggestion {
   padding: 10px;
   cursor: pointer;
   margin-block-start: 0;
   margin-block-end: 0
}

.suggestion:hover {
   background-color: #eee;
}


@media screen and (max-width:1056px) {
   /* .r-circle2 {
      top: 365px;
   } */

   .medisageLogo {
      margin-top: 35px;
   }

   .s-logo {
      width: 42%;
   }
}

@media screen and (max-width:900px) {
   .s-logo {
      width: 60%;
   }
}

@media screen and (max-width:900px) {
   .r-container {
      flex-direction: column-reverse;
      padding: 1rem 1rem 0rem 1rem;
   }

   .r-text {
      font-size: 15px;
   }

   /* .r-circle1 {
      top: 95px;
   }

   .r-circle2 {
      top: 330px;
   } */

   .s-logo {
      width: 40%;
      margin-bottom: 10px;
   }

   .button-div {
      width: 100%;
      text-align: center;
   }

   .medisageLogo {
      text-align: center;
      margin-left: 0;
   }
   .r-desc{
      font-size: 12px;
   }
   .r-circle1 {
      font-size: 12px;
      width: 35px;
      height: 35px;
      margin-top: -15px;
   }
}