.background-blur{
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   backdrop-filter: blur(25px);
   z-index: 99;
   height: 100%;
   width: 100%;
}
.flexDiv{
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
}
.popup{
   width: 35%;
   background-color: #fff;
   border-radius: 20px;
   box-shadow: 0 1px 5px rgb(0 0 0 / 0.2);
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 3rem;
   /* margin-bottom: 3rem; */
}
.img{
   width: 50%
}

@media screen and (max-width:920px){
   .popup{
      margin-bottom: 4rem;
   }
   .popup{
      width: 70%;
   }
}